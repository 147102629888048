<script setup lang="ts">
import type { ValidationProp, InputAttributes, StringOption } from '@year13/ui';
import { useVuelidate } from '@vuelidate/core';

interface Props extends InputAttributes {
  disabled?: boolean;
  rules?: ValidationProp;
  placeholder?: string;
  options: StringOption[];
  errorMessages?: string[];
  customClasses?: string;
  initialLoading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  rules: undefined,
  placeholder: 'Search',
  errorMessages: () => [],
  customClasses: undefined,
  initialLoading: false,
});

const stateId = defineModel<string | null>({ required: false, default: null });
const validation = useVuelidate({ stateId: { ...props.rules } }, { stateId });

const _errorMessages = computed(() => {
  const errors = validation.value.$errors.map((e) => unref(e.$message));
  return [...props.errorMessages, ...errors];
});

const onBlur = () => {
  validation.value.$touch();
};
</script>

<template>
  <UISelectCustom
    v-model="stateId"
    :errorMessages="_errorMessages"
    :label="label"
    :options="options"
    :placeholder="placeholder"
    :disabled="disabled"
    clearable
    :autoClear="false"
    :customClasses="customClasses"
    data-testid="core-state-select"
    :initialLoading="initialLoading"
    :required="required"
    @blur="onBlur"
  />
</template>
